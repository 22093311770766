import { Box, BoxProps } from 'nft/components/Box'
import { useIsMobile } from 'nft/hooks'
import { ForwardedRef, forwardRef } from 'react'
import { Z_INDEX } from 'theme/zIndex'

// import * as styles from './NavDropdown.css'

export const NavDropdown = forwardRef((props: BoxProps, ref: ForwardedRef<HTMLElement>) => {
  const isMobile = useIsMobile()
  return (
    <Box
      ref={ref}
      style={{ zIndex: Z_INDEX.modal }}
      className="absolute top-0 !left-0 !right-auto bg-[#1b1b1b] py-[10px] translate-y-[5px] rounded-[16px] backdrop-blur-[66px]"
      {...props}
    />
  )
})

NavDropdown.displayName = 'NavDropdown'
