import { Box } from 'rebass/styled-components'
import styled from 'styled-components/macro'

const Card = styled(Box)<{ width?: string; padding?: string; border?: string; $borderRadius?: string }>`
  width: ${({ width }) => width ?? '100%'};
  padding: ${({ padding }) => padding ?? '1rem'};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? '16px'};
  border: ${({ border }) => border};
`
export default Card

export const LightCard = styled(Card)`
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(23.999998092651367px);
`

export const GrayCard = styled(Card)`
  background: conic-gradient(from -6deg at 100% 0%, #FFF 15.605940967798233deg, #FFF 117.24214553833008deg, #FF2748 235.38886070251465deg, #6200FF 312.7477169036865deg, #FF1A41 357.0234990119934deg);
  box-shadow: 0px 4px 114px 0px rgba(118, 5, 232, 0.63);
  filter: grayscale(1);
  padding: 13px 0;
`

export const DarkGrayCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
`

export const DarkCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundSurface};
`

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.deprecated_bg3};
`

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.deprecated_yellow3};
  font-weight: 500;
`

export const BlueCard = styled(Card)`
  background-color: ${({ theme }) => theme.deprecated_primary5};
  color: ${({ theme }) => theme.accentAction};
  border-radius: 12px;
`
