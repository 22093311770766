import { Trans } from '@lingui/macro'
import { sendAnalyticsEvent } from '@uniswap/analytics'
import { SwapEventName, SwapPriceUpdateUserResponse } from '@uniswap/analytics-events'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { useUSDPrice } from 'hooks/useUSDPrice'
import { getPriceUpdateBasisPoints } from 'lib/utils/analytics'
import { useEffect, useState } from 'react'
import { AlertTriangle, ArrowDown } from 'react-feather'
import { Text } from 'rebass'
import { InterfaceTrade } from 'state/routing/types'
import styled, { useTheme } from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { isAddress, shortenAddress } from '../../utils'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { ButtonPrimary } from '../Button'
import { LightCard } from '../Card'
import { AutoColumn } from '../Column'
import { FiatValue } from '../CurrencyInputPanel/FiatValue'
import CurrencyLogo from '../Logo/CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import TradePrice from '../swap/TradePrice'
import { AdvancedSwapDetails } from './AdvancedSwapDetails'
import { SwapShowAcceptChanges, TruncatedText } from './styleds'

const ArrowWrapper = styled.div`
  padding: 4px;
  border-radius: 12px;
  height: 40px;
  width: 40px;
  position: relative;
  margin-top: -18px;
  margin-bottom: -18px;
  left: calc(50% - 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundSurface};
  border: 4px solid;
  border-color: ${({ theme }) => theme.backgroundModule};
  z-index: 2;
`

const formatAnalyticsEventProperties = (
  trade: InterfaceTrade<Currency, Currency, TradeType>,
  priceUpdate: number | undefined,
  response: SwapPriceUpdateUserResponse
) => ({
  chain_id:
    trade.inputAmount.currency.chainId === trade.outputAmount.currency.chainId
      ? trade.inputAmount.currency.chainId
      : undefined,
  response,
  token_in_symbol: trade.inputAmount.currency.symbol,
  token_out_symbol: trade.outputAmount.currency.symbol,
  price_update_basis_points: priceUpdate,
})

export default function SwapModalHeader({
  trade,
  shouldLogModalCloseEvent,
  setShouldLogModalCloseEvent,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
}: {
  trade: InterfaceTrade<Currency, Currency, TradeType>
  shouldLogModalCloseEvent: boolean
  setShouldLogModalCloseEvent: (shouldLog: boolean) => void
  allowedSlippage: Percent
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const theme = useTheme()

  const [lastExecutionPrice, setLastExecutionPrice] = useState(trade.executionPrice)
  const [priceUpdate, setPriceUpdate] = useState<number | undefined>()

  const fiatValueInput = useUSDPrice(trade.inputAmount)
  const fiatValueOutput = useUSDPrice(trade.outputAmount)

  useEffect(() => {
    if (!trade.executionPrice.equalTo(lastExecutionPrice)) {
      setPriceUpdate(getPriceUpdateBasisPoints(lastExecutionPrice, trade.executionPrice))
      setLastExecutionPrice(trade.executionPrice)
    }
  }, [lastExecutionPrice, setLastExecutionPrice, trade.executionPrice])

  useEffect(() => {
    if (shouldLogModalCloseEvent && showAcceptChanges) {
      sendAnalyticsEvent(
        SwapEventName.SWAP_PRICE_UPDATE_ACKNOWLEDGED,
        formatAnalyticsEventProperties(trade, priceUpdate, SwapPriceUpdateUserResponse.REJECTED)
      )
    }
    setShouldLogModalCloseEvent(false)
  }, [shouldLogModalCloseEvent, showAcceptChanges, setShouldLogModalCloseEvent, trade, priceUpdate])

  return (
    
    <AutoColumn gap="12px" style={{ marginTop: '1rem' }}>
      <LightCard padding="16px 20px">
        <AutoColumn gap="sm">
          <RowBetween align="center">
              <div>
              <p className='opacity-50 text-white'>Pay</p>
              <TruncatedText
                fontSize={58}
                fontWeight={400}
                color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? theme.accentAction : ''}
                data-testid="input-amount"
                className='leading-[65px]'
              >
                {trade.inputAmount.toSignificant(6)}
              </TruncatedText>
              </div>
            <RowFixed gap="0px">
              <CurrencyLogo currency={trade.inputAmount.currency} size="20px" style={{ marginRight: '12px' }} />
              <Text fontSize={20} fontWeight={500} data-testid="input-symbol">
                {trade.inputAmount.currency.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <FiatValue fiatValue={fiatValueInput} />
          </RowBetween>
        </AutoColumn>
      </LightCard>
      {/* <ArrowWrapper>
        <ArrowDown size="16" color={theme.textPrimary} />
      </ArrowWrapper> */}
      <LightCard padding="0.75rem 1rem" style={{ marginBottom: '0.25rem' }}>
        <AutoColumn gap="sm">
          <RowBetween align="center">
            <div>
            <p className='opacity-50 text-white'>Recieve</p>
              <TruncatedText fontSize={54} fontWeight={400} data-testid="output-amount" className='leading-[65px]'>
                {trade.outputAmount.toSignificant(6)}
              </TruncatedText>
              </div>
            <RowFixed gap="0px">
              <CurrencyLogo currency={trade.outputAmount.currency} size="20px" style={{ marginRight: '12px' }} />
              <Text fontSize={20} fontWeight={500} data-testid="output-symbol">
                {trade.outputAmount.currency.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <ThemedText.DeprecatedBody fontSize={14} color={theme.textTertiary}>
              <FiatValue
                fiatValue={fiatValueOutput}
                priceImpact={computeFiatValuePriceImpact(fiatValueInput.data, fiatValueOutput.data)}
              />
            </ThemedText.DeprecatedBody>
          </RowBetween>
        </AutoColumn>
      </LightCard>
      {/* <RowBetween style={{ marginTop: '0.25rem', padding: '0 1rem' }}>
        <TradePrice price={trade.executionPrice} />
      </RowBetween> */}
      <AdvancedSwapDetails trade={trade} allowedSlippage={allowedSlippage} />
      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap="0px" data-testid="show-accept-changes" className='!text-white'>
          <RowBetween>
            <RowFixed>
              {/* <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24 }} /> */}
              <ThemedText.DeprecatedBlack color={theme.textPrimary}>
                <Trans>Price Updated</Trans>
              </ThemedText.DeprecatedBlack>
            </RowFixed>
            <ButtonPrimary
              style={{ padding: '.5rem', width: 'fit-content', fontSize: '0.825rem', borderRadius: '12px' }}
              className='!m-0 !px-[20px] !py-[10px]'
              onClick={onAcceptChanges}
            >
              <Trans>Accept</Trans>
            </ButtonPrimary>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}

      <AutoColumn justify="flex-start" gap="sm" className='py-[20px] opacity-50'>
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <ThemedText.DeprecatedBlack fontWeight={400} fontSize={14} textAlign="left" style={{ width: '100%' }} color={theme.textPrimary}>
            <Trans>
              Output is estimated. You will receive at least{' '} {trade.minimumAmountOut(allowedSlippage).toSignificant(6)} {trade.outputAmount.currency.symbol} or the transaction will revert.
            </Trans>
          </ThemedText.DeprecatedBlack>
        ) : (
          <ThemedText.DeprecatedBlack fontWeight={400} fontSize={14} textAlign="left" style={{ width: '100%' }} color={theme.textPrimary}>
            <Trans>
              Input is estimated. You will sell at most{' '}
                {trade.maximumAmountIn(allowedSlippage).toSignificant(6)} {trade.inputAmount.currency.symbol}
              or the transaction will revert.
            </Trans>
          </ThemedText.DeprecatedBlack>
        )}
      </AutoColumn>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '0' }} data-testid="recipient-info">
          <ThemedText.DeprecatedBlack fontSize={14} color={theme.textPrimary}>
            <Trans>
              Output will be sent to{' '}
              {isAddress(recipient) ? shortenAddress(recipient) : recipient}
            </Trans>
          </ThemedText.DeprecatedBlack>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
